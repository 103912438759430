<template>
  <div class="comm_file_upload" style="margin:0;width:100%">
    <div class="header" style="display: flex; flex-direction: row;
  justify-content: space-between; width:100%">
      <h1 class="title" style="color:#0F376A; font-size:14px; font-weight:500;text-align:left;align-items: center; ">{{title}}</h1>
      <el-upload
        class="upload-attachment"
        :multiple="false"
        :action="fileUploadUrl"
        :file-list="fileList"
        :show-file-list="false"
        :before-upload="beforeFileUpload"
        :on-success="handleAttachmentUploadSuccess"
        :on-preview="handlePreview"
      >
        <el-button
          class="upload-action-button"
          round
          outline
          size="mini"
          v-if="isShowBtn"
        >
          {{actionBtnLabel ? actionBtnLabel: $t('uploadFile') }}
        </el-button>
      </el-upload>
      <div v-if="isShowReminderBtn" class="flex-row">
      <el-button
        round
        type="danger"
        style="min-width: 100px"
        size="mini"
        @click="$emit('sendNoaReminder')"
      >
        Send NOA Acknowledgement Reminder
      </el-button>
    </div>
    </div>
    <!-- <div v-if="isShowActionCheckBar" class="flex-row">
      <el-checkbox @change="(v)=>handleChangeCheckBox(v)" v-model="checkedBySupplier" :disabled="$attrs.status!==FINANCE_STATUS[2] || $attrs.isFinancier || checkedBySupplier " >The supplier has acknowledged</el-checkbox>
      <el-checkbox  @change="(v)=>handleChangeCheckBox(v)" v-model="checkedByFinancier" :disabled="$attrs.status!==FINANCE_STATUS[2] || !$attrs.isFinancier  || checkedByFinancier" >The financier has acknowledged</el-checkbox>
    </div> -->
    <el-table
      :data="fileList"
      border
      v-loading="fileTableloading"
      :header-cell-style="HEADER_CELL_STYLE"
      :cell-style="{...CELL_STYLE,textAlign: 'center' }"
      >
      <el-table-column label="File Name"   width="150px"  >
        <template v-slot="scope">
          <h4 class="link-text" @click="handlePreview(scope.row)">{{scope.row.fileName}}</h4>
        </template>
      </el-table-column>
      <el-table-column label="Date and Time Uploaded"  min-width="150px">
        <template v-slot="scope">
        <h4 >{{scope.row.updatedAt}}</h4>
        </template>
      </el-table-column>
      <el-table-column   v-if="isShowActionCheckBar && !isMultiple" label="Financier Acknowledged"  min-width="150px">
        <template v-slot="scope">
         <el-button  v-if="checkedByFinancier" type="success" icon="el-icon-check" circle></el-button>
        <el-button v-else @click="handleChangeCheckBox(false)" type="primary"  :disabled="$attrs.status!==FINANCE_STATUS[2] || !$attrs.isFinancier">Acknowledge</el-button>
        </template>
      </el-table-column>
      <el-table-column v-if="isShowActionCheckBar && !isMultiple" label="Supplier Acknowledged"  min-width="150px">
        <template v-slot="scope">
        <el-button v-if="checkedBySupplier" type="success" icon="el-icon-check" circle></el-button>
        <el-button v-else  @click="handleChangeCheckBox(false)" type="primary" :disabled="$attrs.status!==FINANCE_STATUS[2] || $attrs.isFinancier" >Acknowledge</el-button>
        </template>
      </el-table-column>
      <el-table-column label="Remarks"  min-width="150px">
        <template v-slot="scope">
            <el-input
            type="textarea"
            placeholder="Please input your remarks"
            style="width: 100%"
            v-model="scope.row.remarks"
            :disabled="!allowEdit"
            @change="rowValueChange(scope.row, 'remarks')"
          >
        </el-input>
        </template>
      </el-table-column>
      <el-table-column v-if="!disabledUploadOrDeleteFiles" label="" width="50px" fixed="right" >
        <template v-slot="scope">
          <el-popconfirm
            confirm-button-text='Delete'
            cancel-button-text='No, Thanks'
            icon="el-icon-info"
            icon-color="red"
            title="Are you sure to delete this?"
            @confirm="removeUploadedFile(scope.$index)"
          >
            <el-button  slot="reference" size="small" icon="el-icon-delete" type="danger" circle ></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <file-preview :visible.sync="isOpenFilePreview" :file="previewFile"></file-preview>
  </div>
</template>
<script>
import baseMixin from '@/mixins/base-mixin'
import baseTableMixin from '@/mixins/base-table-mixin'
import FilePreview from '@/components/FilePreview'
import { HEADER_CELL_STYLE, CELL_STYLE, FINANCE_STATUS } from '@/constants.js'
export default {
  name: 'FinancerFileUpload',
  components: { FilePreview },
  mixins: [baseMixin, baseTableMixin],
  props: {
    actionBtnLabel: String,
    isShowActionCheckBar: {
      type: Boolean,
      require: false,
      default: false
    },
    isMultiple: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: 'Upload Documents'
    },
    fieldName: {
      type: String,
      require: false
    },
    uploadFiles: {
      type: Array,
      default: () => {
        return []
      }
    },
    allowEdit: {
      type: Boolean,
      default: true
    },
    disabledUploadOrDeleteFiles: {
      type: Boolean,
      default: false
    }

  },
  data () {
    return {
      HEADER_CELL_STYLE,
      CELL_STYLE,
      FINANCE_STATUS,
      fileTableloading: false,
      isOpenFilePreview: false,
      previewFile: {},
      hasInitFile: false,
      checkedBySupplier: false,
      checkedByFinancier: false
    }
  },
  computed: {
    isShowBtn () {
      return (!this.disabledUploadOrDeleteFiles && (this.isMultiple || (!this.isMultiple && this.fileList.length === 0)))
    },
    fileList () {
      return this.uploadFiles
    },
    isShowReminderBtn () {
      if (this.$attrs?.nftRespDetails && this?.isShowActionCheckBar) {
        if (this.$attrs.status === FINANCE_STATUS[2] && ((this.$attrs?.isFinancier && !this.$attrs.nftRespDetails?.supplierAcknowledgedNoaAt) || (!this.$attrs?.isFinancier && !this.$attrs.nftRespDetails?.financierAcknowledgedNoaAt))) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  created () {
    this.fileUploadUrl = this.$apis.baseUrl + this.$apis.uploadFile
    if (this.$attrs.nftRespDetails) {
      this.checkedBySupplier = !!(this.$attrs.nftRespDetails?.supplierAcknowledgedNoaAt) ?? false
      this.checkedByFinancier = !!(this.$attrs.nftRespDetails?.financierAcknowledgedNoaAt) ?? false
    }
  },
  methods: {
    handlePreview (file) {
      this.previewFile = file
      this.isOpenFilePreview = true
    },
    beforeFileUpload (file) {
      this.fileTableloading = true
    },
    handleAttachmentUploadSuccess (response, file) {
      if (response.code === 1000) {
        const { id, updateTime, originalName } = response.data

        const fileItem = {
          updatedAt: updateTime,
          fileName: originalName,
          sysFileId: id
        }
        this.fileList.push(fileItem)
        this.fileTableloading = false
        this.postDate()
      }
    },
    removeUploadedFile (idx) {
      const file = this.fileList[idx]
      this.fileList.splice(idx, 1)
      if (!file.id && file.sysFileId) {
        this.deleteSingleFileMixin(file.sysFileId, false)
      }
      this.postDate()
    },
    rowValueChange (row, fieldName) {
      this.postDate()
      this.$emit('setRemarksEditFlag')
    },
    postDate () {
      this.$emit('setUploadFiles', this.fileList, this.fieldName)
    },
    handleChangeCheckBox (val) {
      this.$confirm('Are you sure ?', '', {
        confirmButtonText: 'Acknowledge',
        cancelButtonText: 'Cancel',
        type: 'info'
      }).then(() => {
        this.$emit('onUpdateAcknowledgeNoa')
        // this.$message({
        //   type: 'success',
        //   message: 'Check Acknowledgement Successful!'
        // })
      }).catch(() => {
        if (this.$attrs.isFinancier) {
          this.checkedByFinancier = false
        } else {
          this.checkedBySupplier = false
        }

        this.$message({
          type: 'info',
          message: 'Check Canceled!'
        })
      })

      this.$emit('handleCheckACallBack', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.comm_file_upload{
  .upload-attachment {
    margin: auto 2px;
  }
  .upload-action-button {
    color: $primary-color-font-hover;
    min-width: 120px;
    border-color: $primary-color-font-hover;
  }
  .body {
    width: 100%;
    >h4 {
      justify-self: left;
      margin: 0;
      margin-top: 20px;
    }
    .el-table {
      width: 100% !important;
      min-width:600px;
      overflow-x: scroll;
    }
  }

}
</style>
